.ErrorsBanner_card {
    display: flex;
    align-items: center;
    margin: 15px 18px 15px 10px;
    border-radius: 12px;
    border: solid 1px #FF4949;
    background-color: #FAF2ED;
    padding: 12px; 
}

.ErrorsBanner_iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.ErrorsBanner_messagesContainer {
    display: flex;
    flex-direction: column;
}
