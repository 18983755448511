.info-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #CECECE;
  min-height: 43px;
  align-items: center;
  width: 100%;
  gap: 8px;
  text-align: right;
}

.assignation-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
  width: 100%;
  box-sizing: border-box;
}

.bar-button{
  width: fit-content;
  height: 28px; 
  border-radius: 142px;
  border: 1px solid var(--color-brand-primary, #0097E0);
  background: white;
}

.op-button{
  width: fit-content;
  height: 28px;
  border-radius: 142px;
  background: var(--magenta-base-color-magenta-040, #CDB8EB);
  border: 1px solid transparent;
}

.ns-button{
  width: fit-content;
  height: 28px;
  border-radius: 142px;
  background: var(--coolgrey-base-color-coolgrey-020, #DEEFEF);
  border: 1px solid transparent;
}

.wip-button{
  width: fit-content;
  height: 28px;
  border-radius: 142px;
  border: 1px solid var(--yellow-base-color-yellow-050, #FFCC3D);
  background: var(--yellow-base-color-yellow-030, #FFF890);
  border: 1px solid transparent;
}

.RFD-button{
  width: fit-content;
  height: 28px;
  border-radius: 142px;
  border: 1px solid #FFE7CD;
  background:  #FFE7CD;
  border: 1px solid transparent;
}

.RFCH-button{
  width: fit-content;
  height: 28px;
  border-radius: 142px;
  border: 1px solid var(--color-brand-primary, #0097E0);
  background: var(--color-brand-primary, #0097E0);
  border: 1px solid transparent;
}

.s-button{
  width: fit-content;
  height: 28px;
  border-radius: 142px;
  border: 1px solid var(--green-base-color-green-070, #04A632);
  background: var(--green-base-color-green-030, #9FF28F);
  border: 1px solid transparent;
}

.r-button{
  width: fit-content;
  height: 28px;
  border-radius: 142px;
  border: 1px solid var(--green-base-color-green-090, #055700);
  background: var(--green-base-color-green-080, #007F0E);
  border: 1px solid transparent;
}

.op-button:hover,
.ns-button:hover,
.wip-button:hover,
.d-button:hover,
.s-button:hover,
.r-button:hover,
.bar-button:hover {
  color:  var(--color-brand-primary, #0097E0) !important;
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
  background: var(--blue-base-color-blue-010, #D1F3FF);
}

.op-button:disabled,
.ns-button:disabled,
.wip-button:disabled,
.d-button:disabled,
.s-button:disabled,
.r-button:disabled,
.bar-button:disabled {
  color: var(--color-brand-mediumgrey, #828282) !important;
  border: 1px solid var(--color-brand-mediumgrey, #828282);
  background: var(--dropdown-color-background, #FFF);
}

.comments-box{
  min-height: 114px;
  border-radius: 4px;
  background: var(--daikin-vrv-grays-light, #F6F6F6);
  box-shadow: 0px 1px 3px 0px rgba(151, 151, 151, 0.38) inset;
}

.circle{
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

.k-file-info > .k-file-validation-message{
  display: none !important;
}

.k-upload-actions> .k-button-md.k-icon-button{
  display: none;
}

.k-file-info > .k-file-name {
  margin-top: 6px;
}

.k-button-md.k-icon-button,
.k-button .k-button-md .k-button-solid .k-button-solid-base .k-icon-button .k-spinner-increase, 
.k-button .k-button-md .k-button-solid .k-button-solid-base .k-icon-button .k-spinner-decrease{
  background: transparent;
}

.commentsPaginator{
  margin-top: 25px; 
  transform: translateX(-15px);
  border-top: #DCDCDC solid 1px ;
  border-radius: 0 0 8 8;  
  font-size: 14px;  
}

.k-pager-md {
  gap: 11px;
}

.MAW-titles{
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 24px;
}

.code-link{
  word-break: break-all;
  margin-bottom: 16px;
}

.sidePanel_info-container{
  background-color: #EBEBEB;
  display: flex;
  flex-direction: column;
}