.modalContainer > .k-dialog {
  border-radius: 12px;
  width: 489px;
  gap: 11px;
  max-height: 90%;
}

.modalContainer > .k-window > .k-window-content, .k-prompt-container {
  margin: 10px;
}

.k-panelbar {
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  border-left: none;
  border-right: none;
  background: none;
}

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link{
  display: inherit;
  padding-inline: 0;
  margin: 0;

}

/* Hide default button icon inside panelbar and avoid Blocked aria-hidden error */
.k-panelbar >.k-panelbar-item > .k-link > .k-svg-icon {
  position: absolute;
  left: -1px;
  top: auto;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.k-panelbar > .k-item > .k-link.k-selected, .k-panelbar > .k-panelbar-header > .k-link.k-selected,
.k-panelbar > .k-item > .k-link.k-selected:hover, .k-panelbar > .k-item > .k-link.k-selected.k-hover, .k-panelbar > .k-panelbar-header > .k-link.k-selected:hover, .k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover,
.k-panelbar > .k-item > .k-link:hover, .k-panelbar > .k-item > .k-link.k-hover, .k-panelbar > .k-panelbar-header > .k-link:hover, .k-panelbar > .k-panelbar-header > .k-link.k-hover {
  color: #414141;
  background: none;

}

.k-panelbar-content > ol, .k-panelbar-content > ul {
  padding-left: 0;
} 

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link{
  color: #414141;
}

.button-group > .k-button-md, .panelbar-header > .k-button-md{

  padding-inline: 0;

}

.modalContainer{
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-left: inherit;
}

.panelbar-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.expand-button {
  background: none;
  border: none;
  color: #414141;
}

.button-group {
  display: flex;
  align-items: center;
  margin-left: auto; 
  gap: 0;
}

.x-icon{
  color: #8D8D8D;
  font-size: 20px;
  padding: 0;
  margin: 0;
}

.x-button{
  background-color: #F2F2F2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.expand-button:hover{
  background: none;

}

.savedModal_apply-Btn,.savedModal_apply-Btn:hover{
  background-color: var(--daikin-primary-daikin-blue);
  color: white;
}

.savedModal_apply-Btn{
  height: 28px;
  width: 60px;  
  margin-left: 12px;
}

.icon-blue{
  color: var(--daikin-primary-daikin-blue);
}

.modalSave_icon-edit-btn, .modalSave_icon-edit-btn:hover{  
  background: transparent;
  border: none;      
}

.modalSave_icon-edit{
  transform: translateY(3px);
}

.check-margin{
  margin: 0 10px;    
}

.modalSave_checkList{
  display: inline;
  vertical-align: top;
  margin-right: 10px
}

.modalSave_checkitem{
  display: inline-block;  
  margin-right: 10px
}

.gap10{
  gap: 10px;
}

.button-group > .primary-button:focus{
  background-color: var(--daikin-primary-daikin-blue, #0097E0);
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
}