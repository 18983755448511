.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
.checkbox-icon {
    width: 18px;
    height: 18px;
    border: 3px solid #D5D5D5;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.checkbox-icon.checked {
    border: none;
    background-color: #0097e0; 
}