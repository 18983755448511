.header-container {
    top: 75px;
    left: 30px;
    gap: 8px;
  }
  
  .tabstrip-container {    
    border: none;
    display: flex;
    padding-bottom: 10px;
  }
  
  .return-text {
    transform: translateX(-3px);
  }
  
  .header-row {
    width: 97%;
  }

  .switch-container {
    gap: 10px;
  }

  .w-10 {
    width: 10%;
  }
  
  .w-40 {
    width: 40%;
  }
    
  .row-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 16px;  
  }
  
  .card-row {
    padding: 8px 16px;
    width: 100%;
  }

  .card-content {
    gap: 24px;
    width: 100%;
  }
  
  .word-wrap-container {
    word-wrap: break-word;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .custom-border {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .no-border {
    border-radius: 0;
    border-bottom: none;
  }

  .lastRow{
    border-radius: 0;
  }

  
  .row-style{
    margin: '0px 16px';  
    display: 'flex';
    justify-content: 'space-between';
    flex-direction: 'row';
    align-items: 'center';
  }

  .difference-row-item{
    display: inline-table;
    margin: 5px 5px 5px 0;
    text-align: left;
    width: 150px;    
  }

  