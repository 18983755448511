a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.roboto-15-500-2{
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

.primary-nav-button{
  border-radius: 20px;
  color: white;
  display: flex;
  /* min-width: 85px; */
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: var(--daikin-primary-daikin-blue, #0097E0);
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
  fill: white;
}

.secondary-nav-button{
  border-radius: 20px;
  color: var(--vrv-dark-gray);
  display: flex;
  /* min-width: 85px; */
  width: flex;
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 1px solid transparent;
  background-color: white;
  fill: var(--color-brand-primary, #0097E0);
}

.primary-nav-button:hover,
.secondary-nav-button:hover{
  color: var(--vrv-dark-gray);
  border: 1px solid transparent;
  background: var(--blue-base-color-blue-010, #D1F3FF);
  fill: var(--color-brand-primary, #0097E0);
}


.primary-nav-button:active,
.secondary-nav-button:active{
  color: var(--vrv-dark-gray);
  border-radius: 20px;
  border: 1px solid var(--color-brand-primary, #0097E0);
  background: var(--blue-base-color-blue-010, #D1F3FF);
  fill: var(--color-brand-primary, #0097E0);
}

.primary-link,
.primary-link:focus,
.secondary-link:focus{
  color: white;
}

.secondary-link,
.primary-link:active,
.secondary-link:active,
.primary-link:hover,
.secondary-link:hover{
  color: black;
}

.box-shadow {
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.16);
}

.custom-navbar {
  min-height: 40px;
  padding: 10px 20px 8px 20px; 
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navContainer_home span {
  margin-left: 30px;
  padding-left: 15px;
  border-left: solid 2px var(--daikin-primary-daikin-blue);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  color: var(--daikin-primary-daikin-blue);
  text-decoration: none;
}