.MAW_Chip-category {
    padding: 0px 10px;
    margin: 8px;
    border-radius: 180px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.MAW_Chip-category-selected {
    background-color: #0097E0;
    color: white;
    border: 1px solid transparent;
}

.MAW_Chip-category-unselected{
    background-color: transparent;
    color: inherit;
    border: 1px solid #EBEBEB;
}

.MAW_icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
}

.MAW_icon-hidden {
    display: none;
}
