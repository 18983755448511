.button2 {
  position: relative;
  font-size: var(--text-small-size);
  line-height: 20px;
  font-family: var(--text-small);
  color: var(--dominant);
  text-align: center;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-7xs) var(--padding-base);
  background-color: var(--daikin-primary-daikin-blue);
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button1:hover {
  background-color: var(--color-royalblue-100);
}
.or {
  position: relative;
  line-height: 16px;
}
.calendarcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-3xs);
}
.capacity {
  color: var(--color-gray-100);
}
.span {
  color: var(--daikin-primary-daikin-blue);
}
.capacity-100-h-container {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.capacityverticalcontainer {
  align-self: stretch;
  width: 334px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /*gap: var(--gap-7xs);*/
}
.navigation-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-mini);
  font-size: var(--dds-header-h5-size);
}
.scheduleheader,
.scheduleheaderout {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.scheduleheader {
  flex: 1;
  border-radius: 8px;
  background-color: white;
  height: 65px;
  overflow: hidden;
  /* padding: 0 var(--padding-3xs);
  min-width: 100px; */
  box-shadow: 0px -2px 19px 0px rgba(2, 82, 120, 0.10);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: 'visible'
}
.scheduleheaderout {
  /*width: 1301px;*/
  padding: 0 var(--padding-11xl);
  min-width: 500px;
  text-align: left;
  font-size: var(--text-medium-size);
  color: var(--vrv-dark-gray);
  font-family: var(--dds-header-h5);
}

.weekcontainer{
  width: flex;
  height: 38px;
  flex-shrink: 0;
  border: 1px solid #8C8C8C;
  border-radius: 4px;
  flex-direction: row;
  display: flex;
  justify-content: center; /* Alinea verticalmente al centro */
  align-items: center; 

  color: var(--vrv-dark-gray, #1C2325);
  font-feature-settings: 'clig' off, 'liga' off;
  /* dds/body/b6 */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}
.weekcontainer:hover{
  background-color: #F6F6F6;
  border-radius: 4px; /* added 06/06/24 */
}

.weektext{
  height: 78%;
  /* padding: 6px 12px 0px 12px; top, right, bottom, left */
  border-right: 1px solid #8C8C8C;
  justify-content: center; 

  color: var(--vrv-dark-gray, #1C2325);
  font-feature-settings: 'clig' off, 'liga' off;
  /* dds/body/b6 */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

@import "rsuite/dist/rsuite.css";


.dropdown-overlay {
  z-index: 1000; 
}

.dropdownPL{
  width: flex;
  min-width: 120px;
  height: 38px;
  flex-shrink: 0;
  /* border: 1px solid #8C8C8C; */
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.10);
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.dropdownPL:hover{
  background-color: #F6F6F6;
}

.capacityText{
  justify-content: center; 
  color: var(--vrv-dark-gray, #1C2325);
  font-feature-settings: 'clig' off, 'liga' off;

  /* dds/header/h5 */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;

  flex-direction: row;
  display: flex;
  max-height: 38px;
  overflow: hidden;
}

.capacityButton{
  /* display: flex; */
  /* padding: 10px; */
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  border-radius: 4px;
  background: #0097E0;
  color: white;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* dds/header/h6 */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 22px; 146.667%  */
}

@media screen and (max-width: 700px) {
  .calendarcontainer,
  .navigation-right,
  .capacityText {
    display: none;
  }
}

/* Custom date picker component styles */
.css-bw88rr-MuiPickersMonth-monthButton.Mui-selected:focus ,
.css-innj4t-MuiPickersYear-yearButton.Mui-selected:focus,
.css-innj4t-MuiPickersYear-yearButton.Mui-selected:hover,
.css-bw88rr-MuiPickersMonth-monthButton.Mui-selected:hover,
.css-bw88rr-MuiPickersMonth-monthButton.Mui-selected ,
.css-innj4t-MuiPickersYear-yearButton.Mui-selected {
  color: #fff;
  background-color: #0097E0 !important;
}

.css-bw88rr-MuiPickersMonth-monthButton:hover,
.css-innj4t-MuiPickersYear-yearButton:hover {
  background-color: #DFF5FF !important;
}

.css-1tj1myt-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid #DFF5FF !important;
  font-weight: 900;
  font-size: 12px;
}

.css-chvodi-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),
.css-11ec6mp-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected){
  border: 1px solid #0097E0 !important;
  font-weight: 900;
  font-size: 12px;
}