.filter-dropdown {
    position: relative;    
  }
  
  .filter-button {
    width: 180px;
    height: 36px;
    padding: 10px 12px;
    gap: 4px;
    border-radius: 4px 0 0 0;
    background-color: #FFFFFF;
  }
  
  .modal-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 450px;
    max-height: 80vh; 
    display: flex;
    flex-direction: column;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(80vh - 135px);
  }
  
  .modal-body {
    flex: 1;
    overflow-y: auto;
    padding: 20px;    
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  20px;   
  }
  
  .apply-button {
    display: flex;
    align-items: center;
    gap: 8px;    
  }

  .check-border {
    border: 2px solid #D5D5D5 ;
}
  
  .apply-button .material-symbols-outlined {
    font-size: 22px;
    vertical-align: middle;
    color: var(--daikin-primary-daikin-blue);
  }
  
  .close-button {
    margin-left: auto;    

    display: flex;
    min-width: 60px;
    height: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: white;
    border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);

  }
  
  .section-header {
    display: flex;
    align-items: center;
    font-weight: bold;   
    justify-content: space-between;
    
  }
  
  .justify-left {
    margin-left: auto;
  }
  
  .checkbox-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
    gap: 6px;
    max-width: 84px;
    min-width: 84px;
  }
  
  .icon-trash{
    background: none;
    width: 18px;
    height: 15px;    
    transform: translateY(-8px);
  }

  .clear-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;    
  }
  
  .close-icon .material-symbols-outlined {
    font-size: 24px; 
    color: #000;
    margin-right: 5px;
  }

  .gap-10{
    gap: 10px;
  }

  .filter-container{
    padding: 10px 0;    
    background-color: white;
    position: sticky;
    top: -20px;
    z-index: 10;
    display: flex;      
  }

  .search-container{
    margin: 0 10px;
  }
