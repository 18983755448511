.splitCode_toolbar-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    margin: 10px 0;
  }
  
  .splitCode_error-container {
    display: flex;
    height: auto;
    width: 100%;
    background: #FAF2ED;
    border: 1px solid #FF4949;
    border-radius: 4px;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
  }

  
  .splitCode_toolbar-container > .splitCode_search-input {
    width: 290px;
    margin-right: auto; 
  }
  
  .splitCode_find-button {
    margin-left: auto;
    margin-right: 9px;     
    width: 230px;    
  }
  
  .splitCode_Grid{
    border-radius: 8px;
    display: flex;    
    min-height: 100px;
    border: 1px solid transparent;
  }

  .splitCode_Grid-container{
    position: relative;    
    width: fit-content;
    border: 1px solid #CEDDE3;
    border-radius: 8px;
    padding: 2px;
  }