@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
body {
  margin: 0;
  line-height: normal;
  background-color: #F6F6F6;
}
:root {
  /* fonts --------------------------------------------------*/
  /*ScheduleHeaderOut*/
  --text-small: Inter;
  --dds-header-h5: Roboto;

  /*TopContainer*/
  /*ScheduleHeaderOut*/
  --roboto-header-h6: Roboto;
  --text-large: Inter;

  --dds-body-b6: Roboto;
  --dds-header-h6: Roboto;

  /* font sizes --------------------------------------------------*/
  /*ScheduleHeaderOut*/
  --text-small-size: 12px;
  --dds-header-h5-size: 13px;
  --text-medium-size: 14px;
  --roboto-header-h6-size: 15px;

  /*TopContainer*/
  --roboto-header-h6-size: 13px;
  --text-large-size: 13px;
  --text-small-size: 12px;

  /*PageHeader*/
  --roboto-header-h2-size: 29px;
  --dds-body-b6-size: 15px;

  --dds-header-h6-size: 15px;


  /* Colors --------------------------------------------------*/
  /*ScheduleHeaderOut*/
  --accent: #457eff;
  --color-royalblue-100: #094db2;
  --dominant: #fff;
  --daikin-primary-daikin-blue: #0097e0;
  --color-gray-100: #212121;
  --vrv-dark-gray: #1c2325;
  --color-black: #000;
  --color-brand-darkgrey: #4c4c4c;
  --gray: #676f82;
  --studio-darkmode-popuplabels-5e6a86: #5e6a86;
  --dark-gray: #334158;
  --confirm: #16ae65;
  --light-background: #f3f6fd;
  --light-border: #e2e4ea;
  --light-gray: #aeb8cf;
  --warning-feedback: #FFCC3D;
  --color-red-70: #CE3B2B;
  --color-green-80: #007F0E;
  --color-green-20: #D0F8B9;
  
  /*TopContainer*/
  --daikin-tables-row-white: #fff;
  --color-black: #000;
  --daikin-primary-daikin-blue: #0097e0;
  --color-brand-darkgrey: #4c4c4c;
  --gray: #676f82;
  --studio-darkmode-popuplabels-5e6a86: #5e6a86;
  --dark-gray: #334158;
  --confirm: #16ae65;
  --light-background: #f3f6fd;
  --accent: #457eff;
  --light-border: #e2e4ea;
  --light-gray: #aeb8cf;

  /*Tabs*/
  --accent: #457eff;
  --color-royalblue-100: #094db2;
  --dominant: #fff;
  --daikin-primary-daikin-blue: #0097e0;
  --color-gray-200: #8c8c8c;
  --color-gray-100: #212121;
  --daikin-vrv-grays-dark-gray: #1c2325;
  --color-gray-300: rgba(255, 255, 255, 0.5);
  --vrv-medium-gray: #39454a;
  --dark-gray: #334158;
  --color-brand-darkgrey: #4c4c4c;
  --color-black: #000;
  --gray: #676f82;
  --studio-darkmode-popuplabels-5e6a86: #5e6a86;
  --confirm: #16ae65;
  --light-background: #f3f6fd;
  --light-border: #e2e4ea;
  --light-gray: #aeb8cf;
  --magenta-10: #F5F5FF;
  --magenta-20: #E4E0F8;


  /* Gaps --------------------------------------------------*/
  /*ScheduleHeaderOut*/
  --gap-mini: 15px;
  --gap-7xs: 6px;
  --gap-3xs: 10px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-lg: 18px;

  /*TopContainer*/
  --gap-xs: 12px;
  --gap-xl: 20px;
  --gap-lg: 18px;
  --gap-8xs: 5px;

  /* Paddings --------------------------------------------------*/
  /*ScheduleHeaderOut*/
  --padding-7xs: 6px;
  --padding-base: 16px;
  --padding-11xl: 30px;
  --padding-3xs: 10px;
  --padding-xs: 12px;
  --padding-21xl: 40px;

  /* Border radiuses --------------------------------------------------*/
  --br-7xs: 6px;
  --br-9xs: 4px;
  --br-5xs: 8px;
  --br-xl: 20px;

  --boxshadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

/* Font styles */
.roboto-15-500{
  color: var(--vrv-dark-gray, #1C2325);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
}

.roboto-13-400{
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.k-switch-on .k-switch-track {
  border-color: var(--daikin-primary-daikin-blue);
  color: white;
  background-color: var(--daikin-primary-daikin-blue);
}
.k-switch-md .k-switch-track {
  width: 52px;
  height: 32px;
  color: transparent;
}

.k-switch-off .k-switch-track {
  border-color: #EFF8F9;
  background-color: lightgray;
}

.roboto-15-400{
  color: #4C4C4C;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.roboto-15-700{
  color: var(--vrv-dark-gray, #1C2325);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 146.667% */
}

.roboto-23-700{
  font-family: Roboto;
  color: var(--vrv-dark-gray, #1C2325);
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  
}

.roboto-17-700{
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  font-style: normal;    
  color: var(--vrv-dark-gray, #1C2325);
}

.roboto-19-700{
  color: var(--daikin-vrv-grays-dark-gray, #1C2325);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Roboto/Header/H4 */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 136.842% */
  /* justify-content: flex-start; */
}

.roboto-16-700{
  color: var(--daikin-vrv-grays-dark-gray, #1C2325);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 36px;
}

.roboto-29-700{
  color: var(--vrv-dark-gray, #1C2325);
  font-family: Roboto;
  font-size: 29px;
  font-weight: 700;
  line-height: 36px;  
  font-style: normal;
}

.roboto-19-400{
  color: var(--daikin-vrv-grays-dark-gray, #1C2325);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 136.842% */
}

.roboto-error{
  color: var(--dds-status-negative, #FF4949);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.blue-text{
  color:var(--daikin-primary-daikin-blue, #0097E0);
}

/* Buttons styles */
.secondary-button{
  display: flex;
  min-width: 85px;
  /* width: flex; */
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: white;
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
}

.secondary-button:hover, .return-button:hover {
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
  background: var(--blue-base-color-blue-010, #D1F3FF);
}

.secondary-button:focus, .return-button:hover{
  border: 1.5px solid var(--color-brand-secondary, #54C3F1);
  background: var(--blue-base-color-blue-010, #D1F3FF);
}

.secondary-button:active, .return-button:hover {
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
  background: var(--blue-base-color-blue-020, #A6E4FB);
}

.secondary-button:disabled, .secondary-button-disabled {
  color: #8C8C8C;
  border: 1px solid #8C8C8C;
  opacity: 1;
}

.link-text{
  text-decoration: underline;
  color: #0097E0;
  background: transparent;
  cursor: pointer;  
}

.fs-15{
  font-size: 15px;
}

.fs-17{
  font-size: 17px;
}

.return-button{
  width: 125px; 
  display: flex;
  min-width: 85px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: white;
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
}

.k-upload .k-upload-button,
.primary-button{
  color: white;
  display: flex;
  min-width: 85px;
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: var(--daikin-primary-daikin-blue, #0097E0);
  border: 1px solid var(--daikin-primary-daikin-blue, #0097E0);
}

.k-upload .k-upload-button:hover,
.primary-button:hover {
  background: #54C3F1;
  border: 1px solid #54C3F1;
}

a:hover, a:focus{
  text-decoration: none;
}

.primary-button:focus{
  color: white;
  border: 1px solid var(--blue-base-color-blue-010, #D1F3FF);
  background: #54C3F1;
}

.k-upload .k-upload-button:active,
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show,
.primary-button:active{
  color: white;
  text-decoration: none;
  background: var(--blue-base-color-blue-070, #0674C1);
  border: 1.5px solid var(--blue-base-color-blue-070);
}

.primary-button:disabled, .primary-button-disabled {
  border: 1px solid #007DB9;
  opacity: 0.5;
  background: #007DB9;
}

/* Inputs styles */
.input{
  display: flex;
  height: 36px;
  padding: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--color-brand-mediumgrey, #8C8C8C);
  background: #FFF;
}

.k-filtercell-operator{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.k-input.k-datepicker.k-input-md.k-rounded-md.k-input-solid,
.k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md.k-icon-button.k-disabled,
.k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md.k-icon-button.k-clear-button-visible,
.k-textbox.k-input.k-input-md.k-input-solid{
  display: flex;
  height: 36px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--color-brand-mediumgrey, #8C8C8C);
  background: #FFF;
  /* color: #BFBFBF; */
}

.k-input:not(:-webkit-autofill),
.k-input .k-numerictextbox .k-input-md .k-input-solid .k-rounded-md{
  display: flex;
  height: 36px;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--color-brand-mediumgrey, #8C8C8C);
  background: #FFF;
}

/* Kendo datepicker filter */
/* .k-icon.k-svg-icon.k-svg-i-calendar.k-button-icon{
  display: none;
} */
.k-button.k-button-md.k-button-solid.k-button-solid-base.k-icon-button.k-input-button{
  /* width: 100%; */
  /* height: 100%; */
  background-color: transparent;
}

/* Dropdown style */
.k-picker-solid {
  height: 36px;
  border-radius: 4px;
  border: 1px solid var(--daikin-brand-light-grey, #8C8C8C);
  color: #424242;
  background-color: #FFF;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.dropdownHover:hover,
.k-list .k-list-item:hover, 
.k-list .k-list-optionlabel:hover, 
.k-list .k-list-item.k-hover, 
.k-list .k-hover.k-list-optionlabel {
  background-color: #DFF5FF;
}

/* Hover in the selected item of the dropdown */
/* .k-list .k-list-item:hover.k-selected {
  background-color: #DFF5FF;
} */

.k-list .k-list-item.k-selected, 
.k-list .k-selected.k-list-optionlabel {
  background-color: #0097E0;
}

/* Kendo dialog window */
.k-dialog-wrapper .k-dialog{
  border-radius: 12px;
}
.k-dialog-titlebar {
  background-color: transparent;
}

/* Padding */
.generalprops{
  margin-top: 30px;
  padding: 0px 30px 0px 30px; /* top, right, bottom, left */
  min-height: 60px;
}

/* Kendo tabstrip component */
.k-tabstrip-content.k-active, .k-tabstrip > .k-content.k-active {
  display: contents;
  border: none;
}


.css-ja5taz-MuiTooltip-tooltip{
  background-color: var(--rs-tooltip-bg) !important;
  max-width: 350px !important;
}

.k-tabstrip {
  background-color: white;
}

/* Kendo window component */
.k-window-titlebar {
  /* display: none; */
  /* height: 20px; */
  gap: 10px;
  color: var(--daikin-primary-daikin-blue);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Roboto/Header/H4 */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  background: #eff8f9;
  border-radius: 12px 12px 0px 0px;
}

.k-button-md.k-icon-button {
  padding: 4px;
  border-radius: 18px;
  color: #424242;
}


.k-button-flat {
  border-color: transparent !important;
  color: inherit;
  /* background: #0097E0 !important; */
  box-shadow: none !important;
}

/* Close and save kendo form */
.k-window-actions {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-block: 16px;
  padding-inline: 0px;
  border-width: inherit;
}

.k-input-solid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  height: 36px;
}

/* Kendo popup style */
.k-popup {
  display: flex; 
  width: 100% ;
  margin-right: 30px; 
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}


/* Kendo upload component style */
.k-dropzone-hint{
  display: none;
}

.k-upload .k-dropzone, .k-upload .k-upload-dropzone {
  background: #EBEBEB;
  padding: 0px;
  justify-content: flex-end;
}

.k-upload-files.k-reset,
.k-dropzone{
  border: none;
}

.k-upload{
  margin: 8px 0px;
  overflow-x: hidden;
  border: none;
  width: 100%;
  background: var(--grey-base-color-grey-020, #EBEBEB);
}

.k-upload-files.k-reset{
  margin-top: 8px;
  border-radius: 8px;
  background: var(--stroke-below-tables, #DCDCDC);
}

.k-upload .k-upload-files .k-file-name, 
.k-upload .k-upload-files .k-file-size, 
.k-upload .k-upload-files .k-file-validation-message, 
.k-upload .k-upload-files .k-file-summary{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.k-upload .k-upload-files .k-file-name{
  font-weight: 400;
}

.k-upload .k-file-success .k-file-validation-message {
  color: var(--daikin-primary-daikin-blue, #0097E0);
}

.k-upload .k-dropzone .k-upload-status, .k-upload .k-upload-dropzone .k-upload-status{
  display: none;
}

.k-button-md {
  padding-block: 0px;
}

/* Kendo calendar */
.k-calendar .k-calendar-view .k-today {
  color: var(--daikin-primary-daikin-blue);
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link {
  color: #ffffff;
  background-color: var(--daikin-primary-daikin-blue);
  border-radius: 20px;
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner:hover, 
.k-calendar .k-calendar-td.k-selected .k-link:hover {
  border-radius: 20px;
  background-color: var(--daikin-primary-daikin-blue);
}

.k-calendar .k-calendar-cell-inner, .k-calendar .k-link {
  border-radius: 20px;
}

.k-link:hover, .k-calendar-cell-inner:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  background-color: var(--daikin-primary-daikin-blue);
}

.k-calendar .k-calendar-navigation li:hover, .k-calendar .k-calendar-navigation li.k-hover {
  color: var(--daikin-primary-daikin-blue);
}

.k-calendar {
  color: #4C4C4C;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.k-month-calendar .k-calendar-td, .k-calendar-monthview .k-calendar-td {
  border-radius: 20px;
}

.k-calendar .k-calendar-td:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td:hover .k-link, .k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-hover .k-link{
  background-color: var(--blue-base-color-blue-010, #D1F3FF);
}

.k-calendar-header .k-title, .k-calendar-header .k-calendar-title {
  font-weight: bold;
  color: #4C4C4C;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
}

.k-link:hover{
  background-color: transparent;
}

/* For my assigned work card */
.k-listview{
  background-color: transparent;
}

/* Styles for toast notifications */
.k-notification-success {
  border-color: var(--color-green-80);
  background-color: var(--color-green-80);
}

.k-notification-error {
  border-color: var(--color-red-70);
  background-color: var(--color-red-70);
}

.k-notification-warnning {
  border-color: var(--warning-feedback);
  background-color: var(--warning-feedback);
  color: black;
}

.k-notification-info {
  border-color: var(--daikin-primary-daikin-blue);
  background-color: var(--daikin-primary-daikin-blue);
}

.icon{
  transform: translateY(5px);
}

.k-notification-action > .k-svg-icon > svg {
  margin-top: 5px;
}

.k-notification{
  font-family: Roboto;
  font-size: 13px;  
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  padding: 5px 16px 8px;
  border-radius: 8px;
  width: 369px
}
/* Styles for toast notifications */ 

/* Styles for tabs on the side panel */ 
.selected-tab2{
  height: 36px;
  padding: 10px;
  background: white;
  border-radius: 0px;
  border-bottom: 4px solid var(--daikin-primary-daikin-blue);
  border-top: none;
  border-left: none;
  border-right: none;
  transition: background-color 0.3s ease, border-width 0.1s ease;
}

.button-selected {
  background-color: var(--daikin-primary-daikin-blue);
  color: white;
}

.unselected-tab2{
  height: 36px;
  padding: 10px;
  background: white;
  border-radius: 0px;
  border-bottom: 1px solid #D1DCE2;
  border-top: none;
  border-left: none;
  border-right: none;
  transition: background-color 0.3s ease, border-width 0.1s ease;
}

.selected-tab2:hover,
.unselected-tab2:hover{
  color: #54C3F1;
  background: white;
}

.selected-tab2:active,
.unselected-tab2:active{
  color: #114484;
  background: white;
}
/* Styles for tabs on the side panel */ 

/* loader color */
.k-loading-image {    
  color: var(--daikin-primary-daikin-blue);    
}

/* loader size */
.k-loading-image::before, .k-loading-image::after {
  font-size: 96px;
  border-width: 2.5px;
  top: 40%;
}

.loader{
  display: flex !important;
  justify-content: center !important;
  color: #0097E0 !important;
}

/* RadioButton styles */
.k-radio:checked, .k-radio.k-checked {
  border: 0px solid #D5D5D5;
  color: white;
  background-color: #0097e0;
}

.k-radio {
  background-color: #ffffff;
}

.k-radio-md {
  width: 18px;
  height: 18px;
}

.k-radio:focus, .k-radio.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-radio:checked:focus, .k-radio.k-checked.k-focus {
  box-shadow: none;
}

.button-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Progrees bar from MAW */
.k-progressbar .k-selected {
  border-color: transparent;
  color: white;
  background-color: #0097e0;
}

.k-progressbar {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #F6F6F6;
  border-radius: 0px;
  height: 16px;
}

.disabled-icon {
  opacity: 0.5;
  pointer-events: none;
}

/* To manage responsiveness */
.hide-class {
  display: none;

  @media (min-width: 1000px) {
    display: flex;
  }
}