.custom-notification {
    display: flex;
    align-items: center;
    background-color: var(--color-red-70);; 
    color: white;
    padding: 16px;
    border-radius: 8px;
    margin: 16px 0 0px;    
    /* font-size: 17px; */
    line-height: 18px;
    font-weight: 400;
    height: 55px;
  }
  
  .custom-notification .icon {
    margin-right: 16px;
    font-size: 24px;
    transform: translateY(0px);
  }

  .custom-notification .notification-message {
    flex-grow: 1;
  }
  
  .custom-notification .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 32px;
    cursor: pointer;
    margin-left: auto;
    transform: translateY(0px);
    
  }
  
  .custom-toast {
    z-index: 1000;
  }
  
  .material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;    
    -webkit-font-smoothing: antialiased;
  }
  