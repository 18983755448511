.k-grid .k-table-row {
    border-color: inherit;
}

.k-grid .k-table-row .hidden-child{
    visibility: hidden;
}

.k-grid .k-table-row:hover .hidden-child{
    visibility: visible; 
    background-color: #D1F3FF;
    border: none;   
}

.k-grid-content-sticky,
.k-master-row:hover .k-grid-content-sticky, .k-master-row:hover .k-grid-row-sticky, .k-master-row.k-hover .k-grid-content-sticky, .k-master-row.k-hover .k-grid-row-sticky,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover, .k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
    background-color: #D1F3FF;
}

.k-grid {
    border-color: #CEDDE3;
}

.k-grid-md .k-filter-row td, .k-grid-md .k-filter-row .k-table-td, .k-grid-md .k-filter-row .k-table-th {
    padding-block: 8px;
    padding-inline: 8px;
    border-top: 1px solid #CEDDE3;
    border-bottom: 2px solid #CEDDE3;
    background-color: white;

}

.k-column-title {
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    overflow: hidden;
    color: var(--dds-brand-neutral-dark, #4C4C4C);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 169.231% */
    text-transform: uppercase;
    /* height: 50px; */
}

.k-header .k-link{
    text-align: start;
 }

.k-grid .k-grid-header.k-grid-draggable-header{
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    /* header background color */
    background-color: white; 
}
    /* header background color */
.k-grid-header-wrap, .k-grid .k-cell-inner > .k-link {
    border-top-left-radius: 8px;
    background-color: white;
}

.k-pager {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: white;
}

.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer {
    color: #424242;
    background-color: transparent;
}

.k-grid-header .k-sort-icon, .k-grid-header .k-sort-order {
    color: #424242;
}

.k-pager-md .k-pager-numbers-wrap .k-button {
    border-radius: 20px;
    color: #0097E0;
}

.k-grid .k-table-th, .k-grid td, .k-grid .k-table-td {
    border-width: 0;
    border-inline-start-width: 1px;
    border-style: solid;
    border-color: #FAFAFA;
}

/* stripped row color */
.k-grid .k-table-row.k-table-alt-row {
    background-color: rgba(240, 245, 248, 0.5);
}

/* stripped row color */
.k-master-row.k-table-alt-row .k-grid-content-sticky, .k-master-row.k-table-alt-row .k-grid-row-sticky {
    background-color: rgba(240, 245, 248, 0.5);
}

.k-grid .k-table .k-grid-header-sticky, .k-grid .k-table .k-grid-content-sticky, .k-grid .k-table .k-grid-footer-sticky {
    border-radius: none;
    border-inline-end-color: #FAFAFA;
    border-inline-start-color: #FAFAFA;
    box-shadow: -4px 0 8px 0 rgba(206, 221, 227, 0.2), 4px 0 8px 0 rgba(206, 221, 227, 0.2);
}

.k-grid .k-column-title {
    white-space: normal;
    max-height: 50px;
    overflow-y: visible;
  }

.k-grid .k-grid-header .k-table-th {
    position: relative;
    vertical-align: bottom;
    cursor: default;
    background: white;
    max-height: 40px;
}

/* Chekbox for drsfthours tab */
.k-checkbox:checked, .k-checkbox.k-checked {
    /* border-color: #D5D5D5; */
    border: 0px solid #D5D5D5;
    color: white;
    background-color: #0097e0;
}

.k-checkbox {
    border: 3px solid #D5D5D5;
    background-color: #ffffff;
    border-radius: 4px;
}

.k-checkbox-md {
    width: 18px;
    height: 18px;
}

.k-grid td.k-selected, .k-grid .k-table-row.k-selected > td, .k-grid .k-table-td.k-selected, .k-grid .k-table-row.k-selected > .k-table-td {
    background-color: #D1F3FF;
}

.k-checkbox:focus, .k-checkbox.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-checkbox:checked:focus, .k-checkbox.k-checked.k-focus {
    box-shadow: none;
}

.k-grid-toolbar {
    background: #FAFAFA;
}

/* .k-checkbox:checked, .k-checkbox.k-checked {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27white%27 stroke-linecap=%27square%27 stroke-linejoin=%27square%27 stroke-width=%272%27 d=%27M3,8 l3,3 l7-7%27/%3e%3c/svg%3e);
} */