.k-tabstrip-items .k-link,  .k-tabstrip-items{
    padding: 0;
    display: none;
    visibility: hidden;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper {
    border-bottom-width: 0;
}

.k-tabstrip-content.k-active, .k-tabstrip > .k-content.k-active {
    display: block;
    border: none;
}

/* k-button k-button-md k-button-solid k-button-solid-base k-rounded-md main-c-shadow unselected-tab  */
.selected-tab{
    display: flex;
    height: 45px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px 8px 0 0;
    border: none;
    background: var(--daikin-brand-white, #FFF);
    padding: 0 40px 0 40px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.21);
    transition: background-color 0.3s ease, border-width 0.5s ease;
}

.unselected-tab:hover,
.selected-tab:hover{
    background: #D1F3FF;
    color: var(--daikin-primary-daikin-blue);
}

.unselected-tab:active,
.selected-tab:active{
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--color-brand-primary, #0097E0);
    background: #D1F3FF;
}


.unselected-tab{
    display: flex;
    height: 45px;
    flex-direction: column;
    align-items: center;
    color: var(--daikin-primary-daikin-blue);
    border-radius: 8px 8px 0 0;
    border: none;
    background: #EFF8F9;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.16);
    padding: 0 40px 0 40px;
    transition: background-color 0.3s ease, border-width 0.5s ease;
}

.k-button-solid-base:focus, .k-button-solid-base.k-focus {
    /* box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08); */
    box-shadow: none;
}

.k-button:hover{
    /* opacity: 0.04; */
    /* background: var(--daikin-brand-white, #FFF); */
    opacity: none;
}

.main-c-shadow{
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.10) 
}


/* Grid index */
.k-animation-container-relative {
    position: relative;
    z-index: 0;
    display: inline-block;
}

/* Chips styles */
.chipList{
    margin-left: inherit;
}

.chipsContainer{
    margin-right: 5px; 
    margin-top: 10px
}

.k-chip-solid-base {
    border: none;
    height: 20px;
}

.customChipBackground {
    background-color: #EFF8F9;
    background: #EFF8F9;
  }

.customChipCodeBackground {
    background-color: var(--magenta-10);
    background: var(--magenta-10);
}

.k-svg-icon.k-icon-sm {
    color: #0097E0;
    width: 16px;
    height: 16px;
  }

.k-chip-solid-base:hover, .k-chip-solid-base.k-hover, .k-chip-solid-base.k-selected:hover {
    background-color: #DFF5FF;
}

.k-chip-solid-base.customChipCodeBackground:hover, .k-chip-solid-base.customChipCodeBackground.k-hover, .k-chip-solid-base.customChipCodeBackground.k-selected:hover {
    background-color: var(--magenta-20);
}

.k-chip-list-md {
    gap: 0;
}


.k-chip-solid-base.k-selected {
    background-color: #EFF8F9 ;
}

.k-chip-solid-base.customChipCodeBackground.k-selected {
    background-color: var(--magenta-10);
}

.k-chip-solid-base:disabled, .k-chip-solid-base.k-disabled, 
.k-chip-list-md:disabled, .k-chip-list-md.k-disabled, .customChipBackground:disabled {
    background-color: transparent;    
}

.k-disabled, .k-widget[disabled] {
    opacity: initial;
}

/* For my assigned work card */
.my-work-card{
    border-radius: 8px;
    border: 1px solid var(--stroke-below-tables, #DCDCDC);
    background: #FFF;
}

.tabSplit{
    margin: 0 5px;
    color: #CECECE;
}

.chipListContainer{
    margin: 20px 15px 15px 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between
}